import Ellipse30 from '@/assets/messageDemo/Ellipse30.jpg';
import Ellipse31 from '@/assets/messageDemo/Ellipse31.jpg';
import Ellipse28 from '@/assets/messageDemo/Ellipse28.jpg';
import Ellipse26 from '@/assets/messageDemo/Ellipse26.jpg';
import Ellipse12 from '@/assets/messageDemo/Ellipse12.jpg';
import Ellipse22 from '@/assets/messageDemo/Ellipse22.jpg';
import Ellipse17 from '@/assets/messageDemo/Ellipse17.jpg';
import Ellipse14 from '@/assets/messageDemo/Ellipse14.jpg';
import Ellipse20 from '@/assets/messageDemo/Ellipse20.jpg';
import Ellipse23 from '@/assets/messageDemo/Ellipse23.jpg';
import Ellipse18 from '@/assets/messageDemo/Ellipse18.jpg';
import Ellipse21 from '@/assets/messageDemo/Ellipse21.jpg';
import Ellipse27 from '@/assets/messageDemo/Ellipse27.jpg';
import Ellipse29 from '@/assets/messageDemo/Ellipse29.jpg';

export const jsonData = {
  code: 200,
  message: 'OK',
  data: {
    length: 8,
    contacts: [
      {
        label: 'A',
        children: [
          {
            app: '',
            id: 'demo-device-dddd0002-89bc18857f290a7d3eb1377d395f9d80',
            name: 'Alex',
            address: '456 Starshine Rd, 2E',
            add_time: 1695249300,
            email: 'alex@example.com',
            phone: '555-012-3456',
            profile_picture_url: Ellipse30,
          },
          {
            app: '',
            id: 'demo-device-dddd0002-f65b9f458b862cf57da75bb2c00320d7',
            name: 'Ava',
            address: '123 Birch Rd, Apt 3C',
            add_time: 1694636700,
            email: 'ava@example.com',
            phone: '555-765-4321',
            profile_picture_url: Ellipse22,
          },
        ],
      },
      {
        label: 'E',
        children: [
          {
            app: '',
            id: 'demo-device-dddd0002-c92e81a5626517a8a26a54a00795ff2b',
            name: 'Emily',
            address: '789 Oak Rd, Suite 7',
            add_time: 1663368300,
            email: 'emily@example.com',
            phone: '555-567-8901',
            profile_picture_url: Ellipse12,
          },
          {
            app: '',
            id: 'demo-device-dddd0002-ae0b62d877eff7533cd7cdc8dc730e43',
            name: 'Ethan',
            address: '234 Pine St, Apt 2A',
            add_time: 1686403229,
            email: 'ethan@example.com',
            phone: '555-234-5678',
            profile_picture_url: Ellipse14,
          },
        ],
      },
      {
        label: 'J',
        children: [
          {
            app: '',
            id: 'demo-device-dddd0002-26138ec6da032d92d48287f0c8ca1ff4',
            name: 'Jackson',
            address: '456 Pineapple Blvd, 9B',
            add_time: 1694695199,
            email: 'jackson@example.com',
            phone: '555-456-7890',
            profile_picture_url: Ellipse21,
          },
        ],
      },
      {
        label: 'L',
        children: [
          {
            app: '',
            id: 'demo-device-dddd0002-02572533a83527c1e71ac5e83ff5b49e',
            name: 'Liam',
            address: '890 Cedar Ave, Suite 5',
            add_time: 1694545799,
            email: 'liam@example.com',
            phone: '555-345-6789',
            profile_picture_url: Ellipse20,
          },
          {
            app: '',
            id: 'demo-device-dddd0002-f79a390550d0683a1a514f95a40ebf37',
            name: 'Lily',
            address: '123 Moonbeam St, 7D',
            add_time: 1695147599,
            email: 'lily@example.com',
            phone: '555-321-0987',
            profile_picture_url: Ellipse28,
          },
          {
            app: '',
            id: 'demo-device-dddd0002-880d575e477d5ae9c99480aea10db02b',
            name: 'Lucas',
            address: '890 Sunshine Ln, 4C',
            add_time: 1695042599,
            email: 'lucas@example.com',
            phone: '555-890-1234',
            profile_picture_url: Ellipse22,
          },
        ],
      },
      {
        label: 'M',
        children: [
          {
            app: '',
            id: 'demo-device-dddd0002-5151c851f5cf80fa4d14e75d153c0ce8',
            name: 'Maya',
            address: '789 Rainbow Ave, 6F',
            add_time: 1695307800,
            email: 'maya@example.com',
            phone: '555-901-2345',
            profile_picture_url: Ellipse31,
          },
          {
            app: '',
            id: 'demo-device-dddd0002-8aea945cc661747314f3f338f36078ff',
            name: 'Mia',
            address: '234 Grapevine Ave, 1A',
            add_time: 1694885400,
            email: 'mia@example.com',
            phone: '555-789-0123',
            profile_picture_url: Ellipse27,
          },
          {
            app: '',
            id: 'demo-device-dddd0002-0e0e590ea93ec6de67a46e4cef504699',
            name: 'Michael',
            address: '456 Elm Ave, Unit 12',
            add_time: 1615228245,
            email: 'michael@example.com',
            phone: '555-987-6543',
            profile_picture_url: Ellipse29,
          },
        ],
      },
      {
        label: 'N',
        children: [
          {
            app: '',
            id: 'demo-device-dddd0002-c15e2e63c2377d8f0757fce5bff4ed73',
            name: 'Noah',
            address: '567 Waterfront Dr, 3B',
            add_time: 1694987099,
            email: 'noah@example.com',
            phone: '555-210-9876',
            profile_picture_url: Ellipse26,
          },
        ],
      },
      {
        label: 'O',
        children: [
          {
            app: '',
            id: 'demo-device-dddd0002-da07af748505d37728b59ddbcc12271e',
            name: 'Olivia',
            address: '567 Maple Ln, Unit 8',
            add_time: 1694447400,
            email: 'olivia@example.com',
            phone: '555-876-5432',
            profile_picture_url: Ellipse17,
          },
        ],
      },
      {
        label: 'S',
        children: [
          {
            app: '',
            id: 'demo-device-dddd0002-6bfe0687eba24ff112e7312acdf359c2',
            name: 'Sarah',
            address: '123 Main St, Apt 4B',
            add_time: 1599488133,
            email: 'sarah@example.com',
            phone: '555-123-4567',
            profile_picture_url: Ellipse18,
          },
          {
            app: '',
            id: 'demo-device-dddd0002-330847bb8261717041e07110d240a285',
            name: 'Sophia',
            address: '789 Orange St, Unit 6',
            add_time: 1694794500,
            email: 'sophia@example.com',
            phone: '555-654-3210',
            profile_picture_url: Ellipse23,
          },
        ],
      },
    ],
    update_time: 1697596041,
    total: 15,
  },
};

export const jsonData1 = {
  code: 200,
  message: 'OK',
  data: {
    contacts: [
      {
        app: '',
        id: 'demo-device-dddd0002-5151c851f5cf80fa4d14e75d153c0ce8',
        name: 'Maya',
        address: '789 Rainbow Ave, 6F',
        add_time: 1695307800,
        email: 'maya@example.com',
        phone: '555-901-2345',
        profile_picture_url: Ellipse31,
      },
      {
        app: '',
        id: 'demo-device-dddd0002-89bc18857f290a7d3eb1377d395f9d80',
        name: 'Alex',
        address: '456 Starshine Rd, 2E',
        add_time: 1695249300,
        email: 'alex@example.com',
        phone: '555-012-3456',
        profile_picture_url: Ellipse30,
      },
      {
        app: '',
        id: 'demo-device-dddd0002-f79a390550d0683a1a514f95a40ebf37',
        name: 'Lily',
        address: '123 Moonbeam St, 7D',
        add_time: 1695147599,
        email: 'lily@example.com',
        phone: '555-321-0987',
        profile_picture_url: Ellipse28,
      },
      {
        app: '',
        id: 'demo-device-dddd0002-880d575e477d5ae9c99480aea10db02b',
        name: 'Lucas',
        address: '890 Sunshine Ln, 4C',
        add_time: 1695042599,
        email: 'lucas@example.com',
        phone: '555-890-1234',
        profile_picture_url: Ellipse22,
      },
      {
        app: '',
        id: 'demo-device-dddd0002-c15e2e63c2377d8f0757fce5bff4ed73',
        name: 'Noah',
        address: '567 Waterfront Dr, 3B',
        add_time: 1694987099,
        email: 'noah@example.com',
        phone: '555-210-9876',
        profile_picture_url: Ellipse26,
      },
      {
        app: '',
        id: 'demo-device-dddd0002-8aea945cc661747314f3f338f36078ff',
        name: 'Mia',
        address: '234 Grapevine Ave, 1A',
        add_time: 1694885400,
        email: 'mia@example.com',
        phone: '555-789-0123',
        profile_picture_url: Ellipse27,
      },
      {
        app: '',
        id: 'demo-device-dddd0002-330847bb8261717041e07110d240a285',
        name: 'Sophia',
        address: '789 Orange St, Unit 6',
        add_time: 1694794500,
        email: 'sophia@example.com',
        phone: '555-654-3210',
        profile_picture_url: Ellipse23,
      },
      {
        app: '',
        id: 'demo-device-dddd0002-26138ec6da032d92d48287f0c8ca1ff4',
        name: 'Jackson',
        address: '456 Pineapple Blvd, 9B',
        add_time: 1694695199,
        email: 'jackson@example.com',
        phone: '555-456-7890',
        profile_picture_url: Ellipse21,
      },
      {
        app: '',
        id: 'demo-device-dddd0002-f65b9f458b862cf57da75bb2c00320d7',
        name: 'Ava',
        address: '123 Birch Rd, Apt 3C',
        add_time: 1694636700,
        email: 'ava@example.com',
        phone: '555-765-4321',
        profile_picture_url: Ellipse22,
      },
      {
        app: '',
        id: 'demo-device-dddd0002-02572533a83527c1e71ac5e83ff5b49e',
        name: 'Liam',
        address: '890 Cedar Ave, Suite 5',
        add_time: 1694545799,
        email: 'liam@example.com',
        phone: '555-345-6789',
        profile_picture_url: Ellipse20,
      },
      {
        app: '',
        id: 'demo-device-dddd0002-da07af748505d37728b59ddbcc12271e',
        name: 'Olivia',
        address: '567 Maple Ln, Unit 8',
        add_time: 1694447400,
        email: 'olivia@example.com',
        phone: '555-876-5432',
        profile_picture_url: Ellipse17,
      },
      {
        app: '',
        id: 'demo-device-dddd0002-ae0b62d877eff7533cd7cdc8dc730e43',
        name: 'Ethan',
        address: '234 Pine St, Apt 2A',
        add_time: 1686403229,
        email: 'ethan@example.com',
        phone: '555-234-5678',
        profile_picture_url: Ellipse14,
      },
      {
        app: '',
        id: 'demo-device-dddd0002-c92e81a5626517a8a26a54a00795ff2b',
        name: 'Emily',
        address: '789 Oak Rd, Suite 7',
        add_time: 1663368300,
        email: 'emily@example.com',
        phone: '555-567-8901',
        profile_picture_url: Ellipse12,
      },
      {
        app: '',
        id: 'demo-device-dddd0002-0e0e590ea93ec6de67a46e4cef504699',
        name: 'Michael',
        address: '456 Elm Ave, Unit 12',
        add_time: 1615228245,
        email: 'michael@example.com',
        phone: '555-987-6543',
        profile_picture_url: Ellipse29,
      },
      {
        app: '',
        id: 'demo-device-dddd0002-6bfe0687eba24ff112e7312acdf359c2',
        name: 'Sarah',
        address: '123 Main St, Apt 4B',
        add_time: 1599488133,
        email: 'sarah@example.com',
        phone: '555-123-4567',
        profile_picture_url: Ellipse18,
      },
    ],
    update_time: 1697596041,
    total: 15,
  },
};
